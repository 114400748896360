import "../scss/app.scss";
import Vue from "vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { left, right } from "@popperjs/core";
gsap.registerPlugin(ScrollTrigger);
import "bootstrap";
import VueScrollClass from "vue-scroll-class";

new Vue({
  el: "#app",
  data: {
    toggleMenu: false,
    loaded: false,
    bottomCallout: true,
    isShowAll: false,
    houses: [],
  },
  mounted() {
    this.loaded = true;
    this.HeroAnimation();
    this.InView();
    this.InViewElementWave();
    console.log(this.bottomCallout);
  },
  methods: {
    InView() {
      const trigger = document.querySelectorAll("[data-trigger]");
      trigger.forEach(function(userItem) {
        ScrollTrigger.create({
          trigger: userItem,
          start: "top bottom-=50px",
          end: "bottom top+=50px",
          toggleClass: { targets: userItem, className: "InView" },
        });
      });
    },
    InViewElementWave() {
      gsap.set(".InViewElementWave", {
        y: 30,
        opacity: 0,
      });
      gsap.to(".InViewElementWave", {
        delay: 1,
        duration: 1.5,
        y: 0,
        opacity: 1,
        ease: "power2.out",
        // 複数要素を扱うプロパティ
        stagger: {
          from: "start", //左側から
          amount: 0.8, // 0.8秒おきに
        },
        scrollTrigger: {
          trigger: ".InViewElementWave",
          start: "top bottom-=50px",
          end: "bottom top+=50px",
          scrub: true,
          // markers: true, // マーカー表示
        },
      });
    },
    HeroAnimation() {
      gsap.set(".bandAnimationWrap", {
        transformOrigin: left,
        scaleX: 0,
        position: "relative",
      });
      gsap.set(".bandAnimation", {
        transformOrigin: right,
        scaleX: 1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#2e1f06",
      });
      gsap.set(".topnewsAnimation", {
        yPercent: 100,
        opacity: 0,
      });
      const tl = gsap.timeline({
        repeat: 0,
        defaults: {
          delay: 0.2,
          duration: 0.6,
          ease: "power2.out",
        },
      });
      tl.from(".bandAnimationWrap", {
        scaleX: 0,
      })
        .to(".bandAnimationWrap", {
          scaleX: 1,
        })
        .to(".bandAnimation", {
          delay: 0,
          scaleX: 0,
        })
        .to(
          ".topnewsAnimation",
          {
            duration: 1,
            opacity: 1,
            yPercent: 0,
          },
          "<"
        );
    },
    fetchData(post_type, offset) {
      fetch(
        "/wp-json/wp/v2/" +
          post_type +
          "?acf_format=standard&per_page=100&offset=" +
          offset
      )
        .then((response) => response.json())
        .then((data) => {
          this.houses = data;
          this.isShowAll = true;
        })
        .catch((error) => console.error("Error:", error));
    },
  },
  directives: {
    "scroll-class": VueScrollClass,
  },
});

document.addEventListener("DOMContentLoaded", function() {
  // Assuming the_field('pr') is directly inside the element with ID 'cms-comment'
  const prContent = document.getElementById("cms-comment");

  if (prContent) {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace plain URLs with clickable links
    prContent.innerHTML = prContent.innerHTML.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });
  }
});
